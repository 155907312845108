import './Editor.css';
import config from './config';

import Credits from './Credits.js';

import React, { useMemo, useState, useCallback } from 'react'
import { Editor, Transforms, createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

const CodeBlock = props => {

  if (props.element.isDisplay) {
    return (
      <code className="codeElement display" {...props.attributes}>
        {props.children}
      </code>
    )
  } else {
    return (<code className="codeElement" {...props.attributes}>{props.children}</code>)
  }

}

const HeaderBlock = props => {

  const HeaderTag = props.element.type;
  return <HeaderTag {...props.attributes}>
    {props.children}
  </HeaderTag>

}

const DefaultBlock = props => (
  <p className={"DefaultBlock"} {...props.attributes}>
    {props.children}
  </p>
)

const MainEditor = (props) => {

  const editor = useMemo(() => withReact(createEditor()), []);

  let page = props.location.pathname;
  // let {page} = useParams();

  const handleKeyDown = event => {

    if (event.ctrlKey) {
  
      if (Object.keys(config.ctrlTransforms).includes(event.key)) {

        event.preventDefault();

        const [match] = Editor.nodes(editor, {
          match: n => n.type === config.ctrlTransforms[event.key],
        });

        Transforms.setNodes(
          editor,
          {
            type: match ? config.defaultBlock : config.ctrlTransforms[event.key],
            isDisplay: event.altKey
          },
          {match: n => Editor.isBlock(editor, n) }
        )
  
      }
  
    } else if (event.key === "enter") {

      event.preventDefault();

      Transforms.setNodes(
        editor,
        {
          type: config.defaultBlock
        },
        {match: n => Editor.isBlock(editor, n) }
      )

    }
  
  }

  const saveName = config.localStorageSavePrefix + (page || "newpage");
  const getSave = () => {
    return JSON.parse(localStorage.getItem(saveName)) || config.defaultNewPageData
  }
  const [value, setValue] = useState(getSave())

  const save = newValue => {
    setValue(newValue);
    localStorage.setItem(saveName, JSON.stringify(newValue));
  }

  const renderElement = useCallback(props => {

    switch (props.element.type) {

      case "h1": case "h2": case "h3": case "h4": case "h5":
        return <HeaderBlock {...props} />
      case 'code':
        return <CodeBlock {...props} />
      default:
        return <DefaultBlock {...props} />

    }

  }, []);

  const TableOfContents = () => (

    <div className="tableOfContents">

      <h2>Table of Contents</h2>

      {
        value.filter(x => x.type.startsWith("h")).length === 0 && (
          <i>Create some heading elements with CTRL+[1,2,3,4,5] to see them pop up here!</i>
        )
      }

      <ul>

        {
          value.filter(x => x.type.startsWith("h")).map((x, i) => (
            <li key={i} style={{ marginLeft: (20 * (parseInt(x.type[1]) - 1)) }} >{x.children[0].text}</li>
          ))
        }

      </ul>

    </div>

  )

  return (
    <>

    <TableOfContents />

    <Slate
      editor={editor}
      value={value}
      onChange={save}
    >
      <Editable 
        renderElement={renderElement}
        onKeyDown={handleKeyDown}
      />
    </Slate>


    <Credits />


    </>
  )

}

export default MainEditor;
