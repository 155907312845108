import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Home from './Home';
import Editor from './Editor';

export default function MainRouter() {

    return (

        <Router>

            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/:page" component={Editor} />
            </Switch>

        </Router>

    )

}