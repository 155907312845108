import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Credits from './Credits';
import config from './config';
import Callout from './design/Callout';

function deletePage(x) {

    if ( window.confirm(config.confirmDeleteMessage.replace("{{name}}", x)) ) {
        localStorage.removeItem(x);
    }

    return {...localStorage};

}

export default function Home() {

    const [blobUrl, setBlobUrl] = useState(null);
    const [exportName, setExpName] = useState(null);
    const [newSlug, setNewSlug] = useState("my-new-page")

    let pages = {...localStorage};

    function exportContent() {

        const jsonblob = new Blob([ JSON.stringify(pages) ], {
            type: 'application/json'
        });

        setExpName(`scratchpad-export-${new Date().getTime()}.json`);
        setBlobUrl(URL.createObjectURL(jsonblob));
    
    }

    return (
        <>

            <h1>Welcome to Scratchpad</h1>
            <Callout>
                Scratchpad is a local notetaking app for <u>anything.</u>
            </Callout>

            Scratchpad <i><b>does...</b></i>
            <ul>
                <li>Keep all your notes locally saved</li>
                <li>Persists notes between sessions.</li>
                <li>Work 100% offline</li>
                <li>Support formatting such as headings, styled text, math and <code>code blocks</code></li>
                <li>Allow comprehensive import and export of your work with zero loss in formatting</li>
            </ul>

            Scratchpad <i><b>does not...</b></i>
            <ul>
                <li>Sync your content to any external servers</li>
                <li>Sync your content to any other devices</li>
                <li><b>Ever send a byte of your content away from your device.</b></li>
            </ul>

            <h2>Make a new page</h2>
            <input className="wide" onInput={(e) => {setNewSlug(e.target.value)}} value={newSlug} /> <Link className="widelink" to={"/" + newSlug}>Go</Link>

            <h2>Your pages</h2>
            <ul>

            {
                Object.keys(pages).length === 0 && (
                    <i>You've got no pages! Create some by appending <code>/your/page/name</code> to the end of the URL.</i>
                )
            }

            {
                Object.keys(pages).map((x, i) => (
                    <li key={i}>
                        <Link to={x}>{x}</Link> (<button href="#" onClick={()=>{pages = deletePage(x)}} className={"delete anchor"}>delete</button>)
                    </li>
                ))
            }
            </ul>

            <h3>Export</h3>
            <p>
                Ready to export your content? It's easy, just <button onClick={exportContent}>click here</button> and we'll generate an export. During this process, no data is sent off of your device (you can even do it offline!).
                {
                    blobUrl && (
                        <div className="exportReady">
                            Your export is ready. <a href={blobUrl} download={exportName}>Click here to download.</a>
                        </div>
                    )
                }
            </p>

            <Credits />
        </>
    )

}