import {Link} from 'react-router-dom';

export default function Credits() {

    return (
        <footer>
            <p>
                Scratchpad version 1 (alpha) - <Link to="/">go home</Link>
            </p>

            <p>
                created by <a href="//srg.id.au">srg</a>
            </p>
        </footer>
    )

}