const config = {
    ctrlTransforms: {
        "`": "code",
        "b": "bold",
        "i": "italic",
        "u": "underline",
        "1": "h1",
        "2": "h2",
        "3": "h3",
        "4": "h4",
        "5": "h5"
    },

    defaultBlock: 'paragraph',
    isDisplayElementIdentifier: "block",
    defaultNewPageData: [
        {
          type: 'paragraph',
          children: [{ text: 'Type something!' }],
        }
    ],
    localStorageSavePrefix: "",
    confirmDeleteMessage: "Are you sure you want to delete {{name}}? You cannot undo this action."
}

export default config