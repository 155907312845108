
import './callout.css';

export default function Callout(props) {

    return (
        <div className="callout">
            {props.children}
        </div>
    )

}